<template>
  <div class="ob-ed__cta">
    <LxpButton
      :disabled="invalid"
      variant="primary"
      size="default"
      type="submit"
      ref="next"
      className="sav-btn__div"
      :showIcon="showIcon"
    >
      {{ buttonText }}
      <span v-if="showIcon">
        <img
          v-if="invalid"
          src="@/assets/images/onboarding/checkmark-grey.svg"
          alt="checkmark-grey"
          width="auto"
          height="auto"
        />
        <img
          v-else
          src="@/assets/images/onboarding/check-white.svg"
          alt="check-white"
          width="auto"
          height="auto"
        />
      </span>
      <span v-else></span>
    </LxpButton>
    <span class="ob-ed__context_left">
      {{ $t("onboarding.enter") }}
    </span>
    <span class="ob-ed__context_icon">
      <img
        src="@/assets/images/onboarding/show.svg"
        alt="enter"
        width="auto"
        height="auto"
      />
    </span>
    <span class="ob-ed__context_right">
      {{ $t("onboarding.continue") }}
    </span>
  </div>
</template>

<script>
import { LxpButton } from "didactica";
export default {
  components: { LxpButton },
  props: {
    invalid: {
      type: Boolean,
      default: true
    },
    buttonText: {
      type: String,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    setNextFocus() {
      this.$refs.next.$el.focus();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/onboarding/_onboarding.scss";
</style>
