<template>
  <div class="ob-ed">
    <OnboardingLayout :step="currentStep">
      <template v-slot:content-section>
        <div class="ob-ed__new w-100">
          <div class="ob-ed__main">
            <div class="ob-img__responsive_view">
              <img src="@/assets/images/onboarding/team.svg" alt="gender" />
            </div>
            <h2 class="ob-ed__heading">
              {{ $t("onboarding.gender.heading") }}
            </h2>
            <div class="ob-main__sec">
              <b-form @submit="onNext">
                <ValidationObserver v-slot="{ invalid }">
                  <b-row>
                    <b-col
                      sm="12"
                      md="12"
                      class="ob-responsive__view ob-responsive__pills"
                    >
                      <ValidationProvider
                        name="Gender"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <div class="ob-ed__selection_btn">
                          <Button
                            :class="{ active: gender.id === oForm.gender }"
                            variant="outline-primary"
                            size="default"
                            className="ob-ed__pills"
                            v-for="gender in validGenders"
                            :key="gender.id"
                            :data-gender-id="gender.id"
                            @click="onGenderClick(gender.id)"
                            >{{ gender.name }}</Button
                          >
                          <ValidationProvider
                            name="Other"
                            rules="required"
                            v-slot="{ errors }"
                            v-if="showGenderText"
                          >
                            <span
                              class="ob-responsive__view ob-responsive__pills"
                              ><CInput
                                ref="otherInput"
                                id="gender"
                                :label="$t('onboarding.gender.label')"
                                :placeholder="$t('onboarding.gender.label')"
                                :required="true"
                                :value="oForm.udf_gender"
                                @input="e => (oForm.udf_gender = e)"
                                :maxlength="64"
                              />
                              <span class="error mb-2">{{ errors[0] }}</span>
                            </span>
                          </ValidationProvider>
                        </div>

                        <span class="error mb-2">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <Footer
                    :invalid="validateGender || invalid"
                    ref="footer"
                    :buttonText="$t('general.next')"
                  />
                </ValidationObserver>
              </b-form>
            </div>
          </div>
        </div>
      </template>
    </OnboardingLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SettingsMixin from "@/components/Settings/mixin";
import OnboardingLayout from "../Layout/OnboardingLayout";
import OnboardingMixin from "@/components/Onboarding/mixin";
import Footer from "@/components/Layout/OnboardingPageLayout/Footer.vue";

export default {
  mixins: [SettingsMixin, OnboardingMixin],
  components: { OnboardingLayout, Footer },
  data() {
    return {
      isCompleted: false,
      oForm: {
        gender: "",
        udf_gender: ""
      },
      currentStep: 0,
      showGenderText: false,
      validateGender: true
    };
  },
  computed: {
    ...mapGetters(["genders", "allProfile"]),
    validGenders() {
      return this.allConstants.genders.filter(gender => gender.valid === true);
    }
  },
  mounted() {
    this.currentStep = this.step;
    this.initData();
  },
  methods: {
    initData() {
      const profile = this.allProfile;
      if (profile && profile.gender) {
        this.oForm.gender = profile.gender.id;
        this.setActiveButton(profile.gender.id);
        this.checkGender();
        this.setNextFocus();
      }
    },
    setNextFocus() {
      this.$refs.footer.setNextFocus();
    },
    setActiveButton(selId) {
      if (selId !== null) {
        this.validateGender = false;
      } else if (selId === null) {
        this.validateGender = true;
      }
    },
    onGenderClick(selId) {
      this.oForm.gender = this.allConstants.genders.find(
        gen => gen.id === selId
      ).id;
      this.checkGender();
      // set the focus to next button for enter key to work
      this.setNextFocus();
      // Add a class to the clicked button
      this.setActiveButton(selId);
      this.validateGender = false;
    },
    checkGender() {
      if (this.oForm.gender === 3) {
        this.showGenderText = true;
        setTimeout(() => {
          const el = this.$refs.otherInput.$refs.input;
          el.focus();
          el.scrollIntoView(true);
        }, 100);
      } else {
        this.showGenderText = false;
      }
    },
    onNext(event) {
      event.preventDefault();
      let obj = {
        gender: this.oForm.gender,
        udf_gender: this.oForm.udf_gender
      };
      this.updateOnboarding(obj, this.oForm);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/onboarding/_onboarding.scss";
</style>
