export default {
  props: {
    step: {
      type: Number,
      required: true
    },
    invalid: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    resetForm(form) {
      Object.keys(form).forEach(f => (form[f] = ""));
    },
    updateOnboarding(obj, form) {
      return this.$store.dispatch("updateProfile", obj).then(() => {
        this.resetForm(form);
        this.$emit("onUpdate");
      });
    }
  }
};
